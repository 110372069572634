import * as React from 'react';
import {PageProps} from 'gatsby';
import {ManualContent} from '../components/manual-content';
import {IGraphQLMenuEntry} from '../../graphql.types';
import {mapStateToProps} from '../state/store';
import {connect} from 'react-redux';
import {IUpdateMenuItemsProps, updateMenuItems} from '../state/menu-items.slice';

interface IManualServerData {
    title: string;
    content: string;
    menuItems: Array<IGraphQLMenuEntry>;
}
type IGatsbyManualProps = PageProps<{}, IManualServerData>;
class ManualContentWrapper extends React.Component<IGatsbyManualProps & IUpdateMenuItemsProps> {
    private readonly title: string = '';
    private readonly content: string = '';

    constructor(props: IGatsbyManualProps & IUpdateMenuItemsProps) {
        super(props);

        this.title = props.pageContext.title;
        this.content = props.pageContext.content;
    }

    public componentDidMount() {
        this.props.updateMenuItems(this.props.pageContext.menuItems);
    }

    public render(): React.ReactNode {

        return (
            <ManualContent title={this.title} content={this.content}/>
        );
    }
}

export default connect(mapStateToProps, {updateMenuItems})(ManualContentWrapper);
